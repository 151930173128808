//公共处理方法以及状态集合
// 每页条数
import moment from "moment";
import {
    MessageBox,
    Message
} from "element-ui";

// 每页条数
export const pageSize = 10;
// 可选切换条数
export const pageSizes = [10, 20, 50, 100];

export const convertToRMB = (money) => {
    var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
    var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
    var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
    var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
    var cnInteger = "整"; //整数金额时后面跟的字符
    var cnIntLast = "元"; //整型完以后的单位
    var maxNum = 999999999999999.9999; //最大处理的数字
    var IntegerNum; //金额整数部分
    var DecimalNum; //金额小数部分
    var ChineseStr = ""; //输出的中文金额字符串
    var parts; //分离金额后用的数组，预定义    
    var Symbol = ""; //正负值标记
    if (money == "") {
        return "";
    }

    money = parseFloat(money);
    if (money >= maxNum) {
        alert('超出最大处理数字');
        return "";
    }
    if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
    }
    if (money < 0) {
        money = -money;
        Symbol = "负 ";
    }
    money = money.toString(); //转换为字符串
    if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = '';
    } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
    }
    if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = IntegerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == "0") {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    ChineseStr += cnNums[0];
                }
                zeroCount = 0; //归零
                ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                ChineseStr += cnIntUnits[q];
            }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
    }
    if (DecimalNum != '') { //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = DecimalNum.substr(i, 1);
            if (n != '0') {
                ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (ChineseStr == '') {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (DecimalNum == '') {
        ChineseStr += cnInteger;
    }
    ChineseStr = Symbol + ChineseStr;

    return ChineseStr;
}

//分页数据计算,参total总页数、pageNum当前页，pageSize每页条数
export const getMeta = (total, pageNum, pageSize = 10) => {
    return {
        // 总条数
        total, // 每页条数
        pageSize, // 最后一页,向上取整，保证至少一页
        lastPage: Math.ceil(total / pageSize), //当前页
        pageNum
    }
}

//毫秒时间戳转换,v代表时间，type存在两个值，一个是start代表开始，end代表结束，不传或者不匹配获取当前毫秒
export const getHaoTime = (v, type = "") => {
    let result;
    switch (type) {
        case "start":
            result = moment(v).startOf("day").format("x");
            break;
        case "end":
            result = moment(v).endOf("day").format("x");
            break;
        default:
            result = moment(v).format("x");
            break;
    }
    return Number(result);
};

//设置空值
export const setValue = (v) => {
    for (let key in v) {
        if (v[key] === undefined || v[key] === null || v[key] === "") {
            v[key] = "--";
        }
    }
    return v;
}

//计算index,当前页、每页条数，当前索引
export const getIndex = (current, pageSize, index) => (current - 1) * pageSize + (index + 1);

// 时间是否重叠
const judege = (item, data) => {
    for (let k in data) {
        if (item !== k) {
            if (data[k].s <= data[item].s && data[k].e > data[item].s) {
                return false;
            }
            if (data[k].s < data[item].e && data[k].e >= data[item].e) {
                return false;
            }
        }
    }
    return true;
};

// 时间重叠，遍历，无重叠，返回true，有返回false
export const timeOverlay = (data) => {
    for (let k in data) {
        if (!judege(k, data)) {
            return false;
        }
    }
    return true;
};

// 直接对比两个对象之间的属性和值，不对比指针
export const isObjectValueEqual = (a, b) => {
    //取对象a和b的属性名
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    //判断属性名的length是否一致
    if (aProps.length !== bProps.length) {
        return false;
    }
    //循环取出属性名，再判断属性值是否一致
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
};

//回收订单状态
export const getRecycleState = (v) => {
    switch (v) {
        case "00":
            return '待确认';
        case "02":
            return '待支付';
        case "01":
            return '待绑码';
        case "03":
            return '已绑码';
        case "04":
            return '已收货';
        case "05":
            return '降价收货';
        case "10":
            return '已取消';
        case "20":
            return '已作废';
        case "30":
            return '已退回';
        default:
            return '--'
    }
};


//角色
export const getStoreRole = (v) => {
    switch (v) {
        case '01':
            return '子账号';
        case '02':
            return '总监';
        case '03':
            return '店长';
        case '04':
            return '店员';
        default:
            return '--'
    }
}

// 角色
export const getStoreRoleTwo = (v) => {
    switch (v) {
        case '01':
            return '创建人';
        case '02':
            return '总监';
        case '03':
            return '店长';
        case '04':
            return '店员';
        default:
            return '--'
    }
}


// 回收端角色类型
export const getRecoveryType = (v) => {
    switch (v) {
        case '01':
            return '子账号';
        case '02':
            return '报价师';
        default:
            return '--'
    }
}

// res响应体，title表标题，cb回调
//导表操作
export const guideExcelAction = (res, title = '', cb) => {
    const content = res;
    const blob = new Blob([content]);
    let reader = new FileReader(); // 创建读取文件对象
    reader.addEventListener("loadend", () => {
        cb()
        //
        try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                    type: "error",
                });
            }
        } catch (err) {
            const fileName = `${title}.xlsx`;
            if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        }
    });
    reader.readAsText(blob, "utf-8");
}

//信息拼接
export const infoConcat = (v1, v2) => `${v1 || '--'}${v2 ? '-' + v2 : ''}`;

//模板分类
export const getTemplateCategory = (v) => {
    switch (v) {
        case 'mobile':
            return '手机';
        case 'tablet':
            return "平板";
        case "laptop":
            return '笔记本';
        default:
            return '--'
    }
}

//获取外部渠道名称和标记
// make代表标记
// name代表外部渠道名
export const getOutNameMake = (outType) => {
    switch (outType) {
        case "ygx":
            return {
                make: '一根线',
                    name: '一根线'
            };
        case 'xm':
            return {
                make: '零售通',
                    name: '零售通'
            };
        case 'lb':
            return {
                make: 'SD',
                    name: '盛达'
            };
        case 'lb2':
            return {
                make: 'BD',
                    name: '波动'
            };
        case 'lb3':
            return {
                make: 'BD',
                    name: '波动2'
            };
        case 'fx':
            return {
                make: '繁星',
                    name: "繁星"
            };
        case 'fx-lx':
            return {
                make: '龙翔',
                    name: "龙翔"
            };
        case 'honor':
            return {
                make: '外部',
                    name: "外部"
            };
        case 'haihuishou':
            return {
                make: '外部',
                    name: "外部"
            };
        default:
            return {
                make: '外部',
                    name: '外部'
            }
    }
}

// 表样式
export const tableStyle = {
    border: true,
    size: 'small',
    "header-cell-style": {
        background: '#E4ECFD',
        padding: 0
    },
}


//旧机情况
export const getHealthCode = (v) => {
    switch (v) {
        case 'Nice':
            return '靓机';
        case 'Little_Snow':
            return '小花';
        case 'Big_Snow':
            return '大花';
        case 'Out_Burst':
            return '外爆';
        case 'Inner_Burst':
            return '内爆';
        case 'Other':
            return "其他"
        default:
            return '';
    }
}


// 数字转换
export const convertYypeNumer = (num) => {
    let v = num;
    // 是否类型正确
    let isType = true;
    if (typeof v !== 'number') {
        isType = false;
        v = Number(v);
        if (isNaN(v)) {
            v = 0;
        }
    }
    return {
        isType,
        v
    };
};

// 计算金额值
export const computedMoney = (num1, num2, isAdd = true) => {
    const v1 = convertYypeNumer(num1);
    let v2 = convertYypeNumer(num2);
    if (!v1.isType && !v2.isType) {
        return '--'
    }
    v1.v = Math.abs(v1.v);
    v2.v = Math.abs(v2.v);

    return isAdd ? v1.v + v2.v : v1.v - v2.v;
};


//跳转,isNew为true打开新页面，false当前页面调，url跳转地址
export const openAction = (url, isNew = true) => {
    if (isNew) {
        window.open(url)
    } else {
        window.location.href = url;
    }
}

//输入值限制为数字
export const getNumberValue = (v) => {
    let num = parseInt(v);
    if (isNaN(num)) {
        return null;
    }
    return num;
}

// 输入限制两位数
export const getFloat2Value = (v, maxNum = false) => {
    let str = String(v);
    const i = str.lastIndexOf(".");
    if (i > 0) {
        str = Number(v.substring(0, i + 3));
    }
    if (str >= Number(maxNum) && maxNum !== false) {
        str = maxNum;
    }
    return Number(str) || ''
}
// 输入
export const inputNumber = (v, parent = {}, key, decimals = 0, max) => {
    const regString = `^(0|([1-9]\\d*))${decimals ? ('(\\.\\d{0,' + decimals + '})?') : ''}`
    const reg = new RegExp(regString)
    const matchString = v.match(reg)?.[0] || ''
    let value = ''
    if (max && Number(matchString) > max) {
        value += max
    } else {
        value += matchString
    }
    parent[key] = value
}

// 外部渠道成交显示
export const getExternalName = (v) => {
    switch (v) {
        case "xm":
            return "XM";
        case "lb":
            return "SD";
        case "lb2":
            return "BD";
        case "ygx":
            return "一根线";
        case "fx":
            return "繁星";
        default:
            return ""
    }
}

// 复制
export const copy = (id) => {
    const spanText = document.getElementById(id).innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Message.success("复制成功")
}

// 当前差异状态(平台)
export const getAprState = (v) => {
    switch (v) {
        case "00":
            return "平台待审核";
        case "01":
            return "待复核打款";
        case "02":
            return "待门店打款";
        case "04":
            return "追差完成";
        case "10":
            return "追差驳回";
        case "20":
            return "取消追差";
        default:
            return "--"
    }
}

// 当前差异状态（门店）
export const getAprStateStore = (v) => {
    switch (v) {
        case "00":
            return "门店待审核";
        case "02":
            return "待门店打款";
        case "04":
            return "追差完成";
        case "10":
            return "追差驳回";
        case "20":
            return "取消追差";
        default:
            return "--"
    }
}


// 状态获取
export const getRejectMark = (v) => {
    switch (v) {
        case "00":
            return "平台初审驳回";
        case "01":
            return "平台复核驳回";
        case "02":
            return "门店打款驳回";
        case "03":
            return "门店审核驳回";
        case "04":
            return "回收商撤回申请";
        default:
            return "--"
    }
}

// 责任
export const getUndertakeSubject = (v) => {
    switch (v) {
        case "C":
            return "门店方承担";
        case "M":
            return "回收商承担";
        case "T":
            return "共同承担";
        default:
            return "--"
    }
}

export const getStaffType = (isMajor,type) => {
    if(isMajor){
        return "创建人"
    }
    switch (type) {
        case "01":
            return "子账号";
        case "02":
            return "总监";
        case "03":
            return "店长";
        case "04":
            return "员工";
        default:
            return "--"
    }
}